<template>
  <a-card
      title="超时事件处理"
      :bodyStyle="{'height': '290px'}"
  >
    <a-table
        :columns="columns"
        :data-source="data"
        rowKey="id"
        bordered
        :pagination="false"
        :scroll="{ y: 200 }"
        v-if="data"
    >
      <template
          slot="eventType"
          slot-scope="text, record, index"
      >
        <template v-if="record.editable">
          <a-select
              placeholder="请选择监听状态"
              :value="text"
              @change="e => itemChange(e, record.id, 'eventType')"
              style="width: 180px"
          >
            <a-select-option :value="1">未反馈</a-select-option>
            <a-select-option :value="2">未解决</a-select-option>
          </a-select>
        </template>
        <span v-else>{{ record.eventType === 1 ? '未反馈' : '未解决'}}</span>
      </template>
      <template
          slot="timeoutValue"
          slot-scope="text, record, index"
      >
        <template v-if="record.editable">
          <a-input
              v-if="record.editable"
              style="margin: -5px 0"
              :value="text"
              @change="e => itemChange(e.target.value, record.id, 'timeoutValue')"
          />
        </template>
        <span v-else>{{ text }}</span>
      </template>
      <template
          slot="assignerIdList"
          slot-scope="text, record, index"
      >
        <template v-if="record.editable">
          <a-select
              placeholder="请选择被分配者"
              :value="text"
              @change="e => itemChange(e, record.id, 'assignerIdList')"
              style="width: 180px"
          >
            <a-select-option :value="item.id" v-for="(item, index) in memberList" :key="item.id">{{ item.staffName }}</a-select-option>
          </a-select>
        </template>
        <span v-else>{{ record.assignerNames }}</span>
      </template>
      <template slot="id" slot-scope="text, record, index">
        <template v-if="record.editable">
          <img src="@/assets/image/common/save.png" @click="() => save(record.id)" />
          <a-popconfirm title="确定取消?" @confirm="() => cancel(record.id)">
            <img src="@/assets/image/common/cancel.png" alt="" class="margin-l20">
          </a-popconfirm>
        </template>
        <img src="@/assets/image/common/edit.png" alt=""  :disabled="editingKey !== ''" @click="() => edit(record.id)" v-else>
        <a-popconfirm title="确定删除?" @confirm="() => delItem(record)">
        <img src="@/assets/image/common/delete.png" alt="" class="margin-l20">
        </a-popconfirm>
      </template>
    </a-table>
    <a-row class="margin-t10">
      <a-form-model
          ref="form"
          layout="inline"
          :model="form"
          @submit="submit"
          @submit.native.prevent
      >
        <a-form-model-item prop="eventType">
          <a-select v-model="form.eventType" placeholder="请选择监听状态" style="width: 205px">
            <a-select-option :value="1">未反馈</a-select-option>
            <a-select-option :value="2">未解决</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item prop="timeoutValue">
          <a-input v-model="form.timeoutValue" type="number" placeholder="请填写超时时间" style="width: 205px"></a-input>
        </a-form-model-item>
        <a-form-model-item prop="assignerIdList" v-if="memberList && memberList.length">
          <a-select mode="multiple" v-model="form.assignerIdList" placeholder="请选择被分配者" style="width: 205px">
            <a-select-option :value="item.id" v-for="(item, index) in memberList" :key="item.id">{{ item.staffName }}</a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item>
          <a-button
              type="success"
              html-type="submit"
              :disabled="
                    !form.eventType
                    || !form.timeoutValue
                    || !form.assignerIdList"
              class="bg-green width-150"
          >
            确认添加一行
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </a-row>
  </a-card>
</template>

<script>
import { deleteTimeout, getTimeoutList, saveTimeout, updateTimeout } from '../../../api/problem/problem'
import { getProjectMember } from '@/api/task/task'

export default {
  name: 'editTable',
  props: {
    problemId: {
      type: [String, Number],
      default: ''
    },
    id: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      data: [],
      cacheData: {},
      editingKey: '',
      form: {
        problemId: this.problemId
      },
      columns: [
        {
          title: '监听状态',
          dataIndex: 'eventType',
          scopedSlots: { customRender: 'eventType' }
        },
        {
          title: '超时时间',
          dataIndex: 'timeoutValue',
          scopedSlots: { customRender: 'timeoutValue' }
        },
        {
          title: '被分配者',
          dataIndex: 'assignerIdList',
          scopedSlots: { customRender: 'assignerIdList' }
        },
        {
          title: '操作',
          dataIndex: 'id',
          scopedSlots: { customRender: 'id' }
        }
      ],
      memberList: []
    }
  },
  mounted () {
    this.getList()
    this.getMember()
  },
  methods: {
    getList () {
      getTimeoutList({
        problemId: this.problemId
      }).then(res => {
        this.data = res.data
        this.cacheData = this.data.map(item => ({ ...item }))
      })
    },
    handleChange(value, id, column) {
      const newData = [...this.data];
      const target = newData.find(item => id === item.id);
      if (target) {
        target[column] = value;
        this.data = newData;
      }
    },
    edit(id) {
      const newData = [...this.data];
      const target = newData.find(item => id === item.id);
      this.editingKey = id;
      if (target) {
        target.editable = true;
        this.data = newData;
      }
    },
    save(id) {
      const newData = [...this.data];
      const newCacheData = [...this.cacheData];
      const target = newData.find(item => id === item.id);
      const targetCache = newCacheData.find(item => id === item.id);
      if (target && targetCache) {
        delete target.editable;
        this.data = newData;
        Object.assign(targetCache, target);
        this.cacheData = newCacheData;
      }
      this.editingKey = '';
      console.log(target)
      updateTimeout(target).then(() => {
        this.$message.success('已保存！')
      })
    },
    cancel(id) {
      const newData = [...this.data];
      const target = newData.find(item => id === item.id);
      this.editingKey = '';
      if (target) {
        Object.assign(target, this.cacheData.find(item => id === item.id));
        delete target.editable;
        this.data = newData;
      }
    },
    submit () {
      saveTimeout(this.form).then(() => {
        this.$message.success('添加成功！')
        this.getList()
        this.form = {}
      })
    },
    itemChange (value, id, column) {
      const newData = [...this.data];
      const target = newData.find(item => id === item.id);
      if (target) {
        target[column] = value;
        this.data = newData;
      }
    },
    delItem (item) {
      deleteTimeout({
        id: item.id
      }).then(() => {
        this.$message.success('已删除！')
        this.getList()
      })
    },
    saveData () {

    },
    getMember () {
      getProjectMember({
        projectId: this.id
      }).then(res => {
        this.memberList = res.data
      })
    },
  }
}
</script>

<style scoped>

</style>
